import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const ServiceSection1 = ({
  className,
  sectionTitle,
  sectionDesc,
  contents,
}) => {
  return (
    <section className="relative mb-20 bg-tertiary-50 pb-14 md:mb-32 md:pb-0">
      <div className="container">
        <div className="grid gap-y-12 md:grid-cols-2">
          <div className="order-2 md:order-1 md:py-28 md:pr-10 lg:py-48 lg:pr-20">
            <h1>{sectionTitle}</h1>
            <p>{sectionDesc}</p>
            <ButtonSolid modal="modal-contact" text="Contact" />
          </div>
          <div className="relative order-1 -mx-4 md:order-2 md:mx-0 md:h-full">
            <div className="h-full md:absolute md:left-0 md:w-[50vw]">
              {contents.bgimage && (
                <GatsbyImage
                  image={contents.bgimage.asset.gatsbyImageData}
                  alt={sectionTitle}
                  className="h-full w-full"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection1;
