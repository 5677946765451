import React from "react";
import Link from "gatsby-plugin-transition-link";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderDetailServices = ({ slides, sectionNumber }) => {
  return (
    <nav>
      <ul className="mb-5 space-y-4">
        {slides.map((slide, i) => {
          return (
            <li key={i}>
              {i === sectionNumber ? (
                <>
                  <Link
                    to={slide.link}
                    className={`inline border-b-2 border-primary-500 font-bold text-primary-500 no-underline`}
                  >
                    {slide.heading}
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={slide.link}
                    style={{ textDecoration: "none" }}
                    className={`inline font-bold text-gray-600/70 underline`}
                  >
                    {slide.heading}
                  </Link>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
export default SliderDetailServices;
