import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";

import SliderDetailServices from "../../components/Slider/SliderDetailServices";
import ButtonSolid from "../../components/Button/ButtonSolid";
import PortableText from "../../components/Blog/portableText";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const slides = [
  {
    // image: data.assistedLiving.childImageSharp.gatsbyImageData,
    heading: "Assisted Living",
    text: "RanchView assisted living residents receive help and support tailored to their needs.",
    link: "/assisted-living-encinitas/",
  },
  {
    // image: data.alzheimersCare.childImageSharp.gatsbyImageData,
    heading: "Alzheimer’s Care",
    text: "Residents with Alzheimer’s disease receive personalized memory care that includes therapeutic activity programs.",
    link: "/memory-care-encinitas/",
  },
  {
    // image: data.dementiaCare.childImageSharp.gatsbyImageData,
    heading: "Dementia Care",
    text: "Our staff has specialized training in all forms of dementia and offers 24/7 care and supervision.",
    link: "/dementia-care-san-diego/",
  },
  {
    // image: data.enrichingExperience.childImageSharp.gatsbyImageData,
    heading: "Hospice Care",
    text: "We have a hospice waiver that allows a terminally ill resident to stay with us, and we accommodate hospice services.",
    link: "/hospice-facility-san-diego/",
  },
  {
    // image: data.hospiceCare.childImageSharp.gatsbyImageData,
    heading: "Respite Care",
    text: "Caregivers need care too and that’s why we offer short-term relief that allows them to recharge.",
    link: "/respite-care-san-diego/",
  },
  {
    // image: data.respiteCare.childImageSharp.gatsbyImageData,
    heading: "Enriching Experiences",
    text: "Life is meant to be lived, and we’re committed to ensuring our residents continue to live life to the fullest.",
    link: "/enriching-experiences/",
  },
];

const ServiceSection2 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  sectionNumber,
}) => {
  return (
    <>
      <section className={` ${className || "mb-20 md:mb-32"}`}>
        <div className="container">
          <div className="grid gap-y-10 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-7 md:col-start-1">
              <StyledContent>
                <div className="mb-12 md:mb-16">
                  {title1 && <h2>{title1}</h2>}
                  <div>
                    {description1 && <PortableText blocks={description1} />}
                  </div>
                </div>
                <div className="mb-12 md:mb-16">
                  {title2 && <h3>{title2}</h3>}
                  <div>
                    {description2 && <PortableText blocks={description2} />}
                  </div>
                </div>

                <div>
                  {title3 && <h3>{title3}</h3>}
                  <div>
                    {description3 && <PortableText blocks={description3} />}
                  </div>

                  <ButtonSolid modal="modal-contact" text="Contact Us" />
                </div>
              </StyledContent>
            </div>
            <div className="hidden md:col-span-5 md:col-end-13 md:block">
              <div className="sticky top-32 md:rounded-3xl md:bg-tertiary-50 md:p-10">
                <div>
                  <h3>Programs</h3>
                  <div>
                    <SliderDetailServices
                      slides={slides}
                      sectionNumber={sectionNumber}
                    />
                  </div>
                  <ButtonSolid modal="modal-contact" text="Book a Tour" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSection2;
